import React, { useEffect, Fragment } from "react";
import CTA from "../general/cta";
import Illustration from "../../../../assets/img/kuda_bank_of_the_free.inline.svg";
import { scrollToElement } from "../../../utility/utils";
import BelowFoldCards from "../general/belowFoldCards";
import BadgesEntryFold from "../general/badgesEntryFold";
import ShoppingBagIcon from "../../../../assets/img/shopping-bag.inline.svg"
import PayIcon from "../../../../assets/img/pay-icon.inline.svg"
import CreditCardIcon from "../../../../assets/img/credit-card.inline.svg"
import SpotifyPurchase from "../../../../assets/img/spotify-purchase.inline.svg"
import GiftCardRecipient from "../../../../assets/img/gift-card-recipient.inline.svg"
import GiftCardsPurchases from "../../../../assets/img/gift-cards-list.inline.svg"
import ImageTextRight from "../general/imageTextRight";
import ImageTextLeft from "../general/imageTextLeft";
import FAQList from "../general/faqsList";
import {giftPurchases} from "./static-data/data"
import MoreForYouComponent from "../general/more-for-you";

import TransferIcon from '../../../../assets/img/more-for-you/kuda-transfer.inline.svg'
import KudaCardIcon from '../../../../assets/img/more-for-you/kuda-cards-icon.inline.svg'
import KudaGlobeIcon from '../../../../assets/img/more-for-you/kuda-globe-icon.inline.svg'


const entryContent = {
    title: "Buy gift cards on Kuda to pay subscriptions and shop online around the world.",
    subtitle: "You’ll find gift cards for Apple, Google Play, PlayStation, Amazon and more on the Kuda app.",
    illustration: <Illustration/>
}
const kudaTopFeatures = [
    {
        icon: <ShoppingBagIcon />,
        text: "Buy Apple, iTunes and Play Store gift cards for different countries.",
    },
    {
        icon: <PayIcon />,
        text: "Pay on foreign stores like Amazon and Walmart without a dollar card.",
    },
    {
        icon: <CreditCardIcon />,
        text: "Buy any gift card for yourself or someone else in minutes.",
    },
]
const firstSection = {
    title: "Pay on foreign stores without a dollar card.",
    subtitle: "Shop abroad with gift cards for popular online stores like Amazon, Walmart and Best Buy on the Kuda app.",
    url: "/joinKuda/",
    name: "Join Kuda",
    illustration: <SpotifyPurchase />,
}
const secondSection = {
    title: "Unlock apps, streaming platforms and games.",
    subtitle: "Increase your entertainment options with gift cards for Apple, iTunes, Play Store, Xbox, PlayStation and mobile games.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <GiftCardsPurchases />,
}
const thirdSection = {
    title: "Treat yourself or someone else in minutes.",
    subtitle: "Buy a gift card for yourself or someone else on the Kuda app and it’ll automatically be delivered by email.",
    url: "https://kuda.onelink.me/abUI/344e3dde",
    name: "Download Kuda",
    illustration: <GiftCardRecipient />,
}

const questions = [
    {
        heading: "How do I redeem a gift card?",
        list: (
            <span className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description">To redeem a gift card, follow the instructions in the gift card email. 
                    You’ll usually have to copy the gift card code in the email or click a link in the email to get the code. 
                    Once you’ve copied the code, redeem it by pasting it on the app or website where you want to use it.

                </span>

            </span>),
        index: 1
    },
    {
        heading: "What gift cards can I buy on the Kuda app?",
        list: (
            <span className="flex justify-start flex-wrap mb-5">
               {giftPurchases.map((item, index)=>{
                return(<div className="kuda-gifts-section mb-5" key={index}>{item}</div>)
               })}
            </span>),
        index: 2
    },
    {
        heading: "When do gift cards expire?",
        list: (
            <div className="flex flex-column inApp-contact-ways--wrap">
                <span className="faq-description">Most gift cards are valid for up to five years, but it’s best to use 
                    your gift card within a year of buying it because the value might reduce after that. 
                    Please, look out for expiration details in your gift card email.</span>
            </div>),
        index: 3
    }
]
const moreForYou = [
    {
        icon: <TransferIcon />,
        title: "Transfer & Spend",
        subText: "Send money for free to any Nigerian account with 25 free transfers every month.",
        linkTo: `/en-ng/spend/`
      },
    {
      icon: <KudaCardIcon />,
      title: "Cards",
      subText: "Get a physical or virtual Kuda Visa card for easy online and offline payments.",
      linkTo: `/en-ng/cards/`
    },
    {
        icon: <KudaGlobeIcon />,
        title: "Cardless Payment",
        subText: "Use Pay ID, Pay With USSD, or free transfers to make quick and safe payments without a debit card.",
        linkTo: `/en-ng/cardless-payments/`
      },
  
  ]

const GiftCards = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <BadgesEntryFold
                title={entryContent.title}
                subtitle={entryContent.subtitle}
                illustration={entryContent.illustration}
            />
            <BelowFoldCards topFeatures={kudaTopFeatures} />
            <ImageTextRight
                title={firstSection.title}
                subtitle={firstSection.subtitle}
                illustration={firstSection.illustration}
                name={firstSection.name}
                url={firstSection.url}
            />
            <ImageTextLeft
                title={secondSection.title}
                subtitle={secondSection.subtitle}
                illustration={secondSection.illustration}
                name={secondSection.name}
                url={secondSection.url}
            />
            <ImageTextRight
                title={thirdSection.title}
                subtitle={thirdSection.subtitle}
                illustration={thirdSection.illustration}
                name={thirdSection.name}
                url={thirdSection.url}
            />
          
            <FAQList title={"Gift Card FAQs"} questions={questions} />
            <MoreForYouComponent moreForYou={moreForYou} />
            <CTA />
        </Fragment>
    )
}

export default GiftCards;
