export const giftPurchases = [
"6th Street",
"Adam 'N' Eve",
"Adidas",
"Air Canada",
"Amazon",
"American Eagle Outfitters",
"Apple",
"Applebees",
"Ardene",
"ASOS",
"Banana Republic",
"Belk",
"Best Buy",
"Bier Markt",
"Blizzard Balance",
"BNatural MedSpa",
"Buffalo Wild Wings",
"Burger King",
"Cake Beauty",
"Caribou Coffee",
"Carrefour",
"Casper & Gambini's",
"CB2",
"Chipotle",
"Conquer Online",
"Crate & Barrel",
"Currys",
"David Wej",
"Deliveroo",
"Domino's",
"DoorDash",
"DSW",
"Dunkin' Donuts",
"eBay",
"Emax",
"Filmhouse Cinemas",
"Foot Locker",
"Forever 21",
"Fortnite",
"FreeFire",
"Galeries Lafayette",
"GAME",
"Gamestop",
"Gap",
"Google Play",
"Grocery Bazaar",
"GrubHub",
"H&M",
"Hairitage Hub",
"HealthPlus",
"Hingees",
"Home Depot",
"Hotelgift",
"Hotels.com",
"Hubmart Stores",
"Hulu Plus",
"IKEA",
"IMVU",
"iTunes",
"Jagex RuneScape",
"Keje Grill",
"KL's Naturals",
"Kohl's",
"Macy’s",
"Mastercard",
"MediaMarkt",
"Minecraft",
"Mobile Legends",
"Netflix",
"New Look",
"Nike",
"Nintendo",
"Noon",
"Nordstrom",
"OneandKo",
"Oriki",
"Pizza Hut Restaurants",
"PlayStation",
"PlayStation Plus",
"Primark",
"PUBG",
"PUBG New State",
"Raffles Hotels & Resorts",
"Razer Gold",
"Red Lobster",
"Ruff 'n' Tumble",
"RuneScape",
"Safeway",
"Sainsbury’s",
"Samsung",
"Sephora",
"SPAR",
"Spotify",
"Starbucks",
"Steam",
"Suregifts Generic",
"Swarovski",
"Target",
"The5kShop",
"Twice As Nice",
"Uber",
"Uber Eats",
"Ubuy",
"Visa",
"Walmart",
"Xbox",
"Xbox Live",
"Xbox Live Gold",
"Zalando",
"Zappos"
]
const giftCardsCol2 = [
"CB2",
"Chipotle",
"Conquer Online",
"Crate & Barrel",
"Currys",
"David Wej",
"Deliveroo",
"Domino's",
"DoorDash",
"DSW",
"Dunkin' Donuts",
"eBay",
"Emax",
"Filmhouse Cinemas",
"Foot Locker",
"Forever 21",
"Fortnite",
"FreeFire",
"Galeries Lafayette",
"GAME",
"Gamestop",
"Gap",
]